import React from "react"
import { graphql } from "gatsby"
import { ContactsPageQuery } from "../__generated__/ContactsPageQuery"
import ContactsContainer from "../containers/contacts"
import { LanguageProvider } from "../context/language"
import { LayoutContextProvider } from "../context/contents/layoutContext"
import { withPrismicPreview } from "../root/contents/prismic/preview"
import { PrismicPage } from "../root/contents/prismic/pages/PrismicPage"

interface Props {
  data: ContactsPageQuery
}

const ContactsPageTemplate = ({ data }: Props) => {
  return (
    <LanguageProvider language={data.prismicContactsPage?.lang as string}>
      <PrismicPage page={data.prismicContactsPage}>
        <LayoutContextProvider>
          <ContactsContainer data={data} />
        </LayoutContextProvider>
      </PrismicPage>
    </LanguageProvider>
  )
}

export default withPrismicPreview(ContactsPageTemplate)

export const pageQuery = graphql`
  query ContactsPageQuery($id: ID!) {
    prismicContactsPage(prismicId: { eq: $id }) {
      prismicId
      lang
      data {
        content_background_color {
          text
        }
        content_background_image {
          url
        }
        content_cta_label {
          text
        }
        content_title {
          text
        }
        seo_description {
          text
        }
        title {
          text
        }
      }
    }
  }
`
