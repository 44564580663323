import React from "react"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import { ContactsPageQuery } from "../../__generated__/ContactsPageQuery"
import DefaultLayout from "../../components/layouts/DefaultLayout"
import { LayoutContext } from "../../context/contents/layoutContext"
import { RichTextField } from "../../components/ui/molecules/Fields"
import { FadeIn } from "../../components/ui/animations"
import { whenMobile } from "../../utils/themeUtils"
import { makeStyles } from "@material-ui/core"

interface Props {
  data: ContactsPageQuery
}

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: ({ src }: any) => `url(${src})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "100%",
    height: "140vh",
    position: "relative",
  },
  contentCta: {
    fontSize: "15px",
    width: "100%",
    color: "#FFFFFF",
    height: "22px",
    textAlign: "center",
    letterSpacing: "4.21px",
    marginBottom: "60px",

    [whenMobile(theme)]: {
      marginBottom: "32px",
    },
  },
  logoContainer: {
    width: "100%",
    textAlign: "center",
    height: "19px",
  },
  logo: {
    marginRight: "20px",
    marginLeft: "20px",
    height: "19px",
    width: "19px",
  },
  arrow: {
    color: "#FFFFFF",
    position: "absolute",
    marginTop: "-5px",
  },
  content: {
    top: "36vh",
    position: "absolute",
    width: "100%",

    [whenMobile(theme)]: {
      top: "24vh",
    },

    "@media (max-height:550px)": {
      top: "18vh",
    },
  },
}))

const ContactsContainer = ({ data }: Props) => {
  const { footer } = React.useContext(LayoutContext)
  const resource = data.prismicContactsPage?.data
  const src = resource?.content_background_image?.url
  const classes = useStyles({ src })
  return (
    <div className={classes.background}>
      <DefaultLayout transparentFooter>
        <div className={classes.content}>
          <FadeIn>
            <RichTextField
              variant="h2"
              px={1}
              value={data.prismicContactsPage?.data?.content_title}
              weight="lighter"
              color="white"
              textCenter
            />
            <a
              href="mailto:info@whereigo.it"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className={classes.contentCta}>
                {resource?.content_cta_label?.text?.toUpperCase()}
                <span className={classes.arrow}>
                  <ArrowRightAltIcon />
                </span>
              </p>
            </a>
            <div className={classes.logoContainer}>
              {footer.social.links.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={link.icon.fluid.src} className={classes.logo} />
                </a>
              ))}
            </div>
          </FadeIn>
        </div>
      </DefaultLayout>
    </div>
  )
}

export default ContactsContainer
